.monthNavigation{
  overflow: hidden;
  text-align: center;
  margin-bottom: 10px;
}

.monthForm{
  display: inline-block;
  select{
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
}
