/* ローカル・開発用CSS */

html.is-mobile{
  [data-is-bullet-footer]{
    display: none !important;
  }
  #console{
    display: none;
  }
}

body.controller-master_records{
  > .container{
    padding-bottom: 10px;
  }
}

.markdown-text{
  background-color: #fff;
}
