.container h1{
  margin-top: 0;
  border: 1px solid #dcdcdc;
  padding: 5px 10px;
  font-size: 30px;
  border-left: 5px solid #8FC31F;
  width: 102%;
  margin-left: -1%;
  >:is(.btn, .btn-group){
    float: right;
  }
  &::after{
    content: "";
    display: block;
    clear: both;
  }
}

@media (max-width: 767px) {
  .container h1{
    font-size: 26px !important;
    line-height: 34px;
  }
}

body.turbolinks-loading{
  cursor: wait !important;
  * {
    cursor: wait !important;
  }
}

body > .container{
  padding-bottom: 200px;
}

.contract-label{
  padding: 2px 0;
  margin: 2px;
  text-align: center;
}

body label{
  margin-bottom: 0;
}

.thumbnail{
  position: relative;
  .title{
    position: absolute;
    width: 100%;
    min-height: 1.5em;
    max-height: 50%;
    overflow: hidden;
    text-align: center;
    left: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.7);
    word-break: break-all;
  }
}

/* 基本的なフォームについて、こちらに移植 */
form.input-form{
  label:not(.radio-inline):not(.checkbox-inline){
    font-weight: normal;
    font-size: 12px;
  }
  :is(input, select, textarea):enabled:is(.required, [required]){
    background-color: #ffebeb;
  }
}

input[type="number"]{
  text-align: right;
}

.form-control.emphasized, .emphasized .form-control, .emphasized .input-group-addon {
  border-width: 2px;
}

/* ボタンの内側に入れるチェックボックスやラジオボタンをすべて消す */
label.btn > input{
  &[type="radio"], &[type="checkbox"]{
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }
}

h2.coating-detail{
  text-align: center;
  background-color: #8EC63E;
  color: #fff;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.6;
}

.pdf-icon{
  color: #f33;
}

.help-block.text-danger{
  color: #a94442;
}

.nobr{
  white-space: nowrap;
}

option.placeholder{
  display: none;
}

td.with-table{
  padding: 0;
  > table{
    width: 100%;
    border: none;
    >tbody {
      >tr>:is(td, th){
        text-align: center;
        padding: 8px;
      }
      >tr:not(:first-child)>:is(td, th){
        border-top: 1px dashed #ddd;
      }
    }
  }
}

table.fixed-table{
  table-layout: fixed;
}

.other-navigation{
  width: 100%;
  background-color: #eee;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0 20px;
}


/* モーダル内部 */
.modal .form-control{
  font-size: 16px;
}

input[type="range"]::-webkit-slider-thumb{
  width: 25px;
  height: 25px;
  display: block;
  border-radius: 50%;
}

[data-link-to]{
  cursor: pointer;
  &:is(:hover, .tapped){
    background-color: #fbffdc !important;
  }
}

/* 表示が崩れない最低限 */
.svg-inline--fa{
  height: 1em;
}

/* のちのBootstrapからバックポート */
.d-none{
  display: none !important;
}

.d-inline-block{
  display: inline-block !important;
}
