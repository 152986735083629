/* PC/モバイル共通 */
.selectedDate {
  background-color: #fbffdc
}
/* 曜日別の色部分をまとめる */
.holidayDate>:is(.youbiCol, .dateCol, .dateNum),
.calendarBase>*>tr>th:first-child
{
  color: var(--holiday-color);
}
.saturdayDate>:is(.youbiCol, .dateCol, .dateNum),
.calendarBase>*>tr>th:last-child
{
  color: var(--saturday-color);
}

/* PC */
.calendarBase{
  table-layout: fixed;
  margin-top: 10px;
  >*>tr{
    >td{
      height: 80px;
      &.otherMonth{
        background-color: #eee;
      }
    }
    >th{
      text-align: center;
    }
  }
  td.todayDate{
    border-width: 3px;
  }
}

.dateNum{
  display: block;
}

/* モバイル */
.calendarMobile{
  table-layout: fixed;
  tr.todayDate>td{
    border-top-width: 3px;
    border-bottom-width: 3px;
    &:first-child{
      border-left-width: 3px;
    }
    &:last-child{
      border-right-width: 3px;
    }
  }
}

.youbiCol{
  text-align: center;
  width: 3em;
}
th.dateCol{
  width: 4em;
  text-align: center;
}

