/*
  ヘルプ用のCSS
  React、測定用で共通化するために別に用意
*/

:short-class(help_popup_outer){
  position: relative;
  font-size: 14px;
  font-weight: normal;
  svg{
    display: inline-block !important;
    color: #6C9BD2;
    cursor: pointer;
  }
}
