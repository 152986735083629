/* ファイル枠用のCSS */

.fileFrame{
  position: relative;
  /* 使用箇所が不明だけど、いったん存置 */
  p{
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.innerWrapper{
  width: 100%;
  position: relative;
  &:before{
    content: '';
    display: block;
    padding-top: 75%;
  }
}

.fileTitle{
  cursor: pointer;
}

.editIcon{
  float: right;
  padding-right: 5px;
}

.deleteButton{
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  z-index: 2;
}

.moveToFront, .moveToBack{
  position: absolute;
  bottom: 25px;
  cursor: pointer;
  opacity: 0.8;
}

.moveToFront{
  left: 5px;
}

.moveToBack{
  right: 5px;
}

.timestamp{
  position: absolute;
  right: 5px;
  bottom: 20px;
  font-weight: bold;
  color: orange;
}

.overlay{
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
  >svg{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}
