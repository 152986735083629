.customizeSearch:global(.line){
  padding: 10px;
  background-color: #f3f9eb;
  /* FIXME: いったん!importantで暫定対応中 */
  margin-top: 0 !important;
}


.bordered{
  border: 1px solid #ccc;
  padding: 5px;
}
