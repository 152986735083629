@import './_layouts_root_container.css';

.navbar-fixed-top{
  width: 100vw;
}

#navbar_jeb_logo{
  display: inline-block;
  height: 0;
  padding-top: 50px;
  padding-bottom: 0;
  line-height: 100px;
  overflow: hidden;
  width: 123px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 991px) {
  #navbar_jeb_logo{
    width: 44px;
  }
}

#navbar_fixed_area{
  float: left;
  margin: 0;
}
#navbar_fixed_area li{
  float: left;
}

#navbar_base {
  .navbar-fixed-top .navbar-collapse{
    max-height: calc(80vh - 100px);
  }
}

.navbar-form{
  padding-right: 0;
}
