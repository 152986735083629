/* h3.jebのスタイル（ジェブ側とお客様側で共用）*/

h3.jeb, summary.jeb{
  display: block;
  font-weight: normal;
  font-size: 14px;
  color: #505050;
  background-image: url(./jeb.inline.svg);
  padding-left: 25px;
  background-repeat: no-repeat;
  background-position: 0 center;
  background-size: 22px;
  margin-bottom: 10px;
}

/* コンパイル結果を見るテスト */

/*
.nestedOuter{
  .nestedInner{
    @media screen{
      color: red;
    }
  }
}
*/
