/* 画像アップロードのファイル・回転部分のCSS */

.thumbImage{
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  max-height: 100%;
  max-width: 100%;
  margin:0 auto auto;
}
.rotateBtn{
  position: absolute;
  left: 5px;
  top: 5px;
  z-index: 10;
  opacity: 0.8
}
