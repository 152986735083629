/*
  勤務予定区分ごとの色
  adminとinformation_calendarで使うのでここに配置
*/

/* 特に指定のないものを白にリセット（詳細度で差はつかないので先頭に書く） */
.defaultType{
  background-color: #fff;
}

.holidayType{
  background-color: #fef;
}

.paidType{
  background-color: #efe;
}

.halfType{
  background-color: #ffe;
}

.movingType{
  background-color: #eef;
}

.requestType{
  background-color: #eff;
}

.specialHolidayType{
  background-color: #fadd9e;
}
