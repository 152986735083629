.outer{
  cursor: pointer;
  &.bookmarked>:global(.fa-star){
    color: #dd0;
    transform: rotate(144deg);
  }
  >:global(.fa-star){
    transition: all 0.3s;
    transform-origin: center 55%;
    color: #ccc;
    &:hover{
      color: #ee0;
    }
  }
}
