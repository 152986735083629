/* 集計側 */
:is(.controller-sekisui_enquetes, .namespace-sekisui.controller-enquetes, .controller-contract_enquetes){

  .chart-wrapper{
    &:before{
      content:"";
      display: block;
      padding-top: 75%;
    }
    >div{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

  }

  td.number{
    text-align: right;
    width: 5em;
  }

  h4{
    font-size: 14px;
  }

  .texts-box{
    border: 1px solid #ddd;
    padding: 5px;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    li:not(:first-child){
      border-top: 1px solid #ddd;
    }
  }
}
