.outer {
  width: 100%;
  >input{
    text-align: center;
  }
}

.middle {
  width: auto;
  min-width: 16px;
  padding: 4px 5px;
  line-height: 1.42857143;
  text-shadow: 0 1px 0 #fff;
  border-width: 1px 0;
  margin-left: -5px;
  margin-right: -5px;
}

.endInput {
  /* input-groupからかかるCSSの詳細度が高いので、!importantをつける */
  border-radius: 0 4px 4px 0 !important;
}
