.controller-enquetes{

  h2{
    font-size: 18px;
    line-height: 1.3
  }
  .input-form{
    line-height: 2;
    label.radio-inline, label.checkbox-inline{
      padding-left: 25px;
      font-size: 1em;
      input[type=radio], input[type=checkbox]{
        margin-left: -25px;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
      }
    }
  }
  .info-table{
    table-layout: fixed;
    th{
      font-weight: normal;
      background-color: #eee;
    }
  }
  @media (min-width: 768px){
    .form-inline input[type=text].form-control{
      width: 250px;
    }
  }

}
