/* 検索結果のモバイル版用CSS */

.mobile-item{
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 5px;
  margin: 10px 0;
  cursor: pointer;
  position: relative;
  .status-label{
    margin-right: 10px;
  }
  p{
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .bookmark-base{
    font-size: 2em;
    position: absolute;
    right: 5px;
    top: 5px;
  }
}
