/* 社内・カスタマーページで共通 */

.controller-online_applications{
  .caution-checks {
    .checkbox label{
      padding-left: 25px;
    }
    input[type="checkbox"]{
      transform: scale(1.5);
      width: 20px;
      margin-left: -25px;
    }
  }
}
