/* ファイルアップロード絡み */

:short-class(upload_files_frame){
  width: calc(100% + 30px);
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: visible;
  >*{
    flex: 1 0 240px;
    width: 100%;
    padding: 0 15px;
  }
}

:short-class(file_upload_caption_edit){
  margin-top: 5px;
  select{
    margin-top: 5px;
  }
}
