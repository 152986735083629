/* 開発サーバでのロゴ表示用CSS */

#navbar_jeb_logo{
  background-image: url(../../dev-images/navbar_logo.svg);
}
@media (max-width: 991px) {
  #navbar_jeb_logo{
    background-image: url(../../dev-images/navbar_logo_mobile.svg);
  }
}
