/* liは、bootstrapの詳細度に勝つために必要 */
li.navbarIconLink>a{
  line-height: 1.3;
  text-align: center;
  padding: 6px 7px;
  font-size: 12px;
  :is(svg, :global(.fa-layers)){
    font-size: 28px;
  }
  :global(.fa-layers-counter){
    background:Tomato;
    transform:scale(0.4);
  }
  img{
    height: 28px;
  }
  @media (max-width: 768px){
    padding: 14px 7px 13px 7px;
  }
}
